<template>
    <div class="cusModal" v-if="showtable">
        <div class="cusModalCont">
            <div class="cusModalTitle backBlack">
                {{ title }}

                <div class="eportBtn">
                    <div style="width:170px;background:darkslateblue;color:#fff;">ارسال امر التشغيل للعميل</div>
                    <div style="width:150px;background:darkgreen;color:#fff;">ارسال المحدد للعميل</div>
                    <div style="background:darkslategray;color:#fff;" @click="addToService()" v-b-toggle.add_service>اضافة شغل يد</div>
                    <div style="background:darkred;color:#fff;" @click="addToStore()" v-b-toggle.add_sparepart>اضافة مخزون</div>
                    <div style="width:80px;background:green;color:white;" @click="printMe()">طباعة</div>
                </div>
                <div class="cusModalClose" @click="closeTable()">{{ lang.close }}</div>
            </div>
            <div class="cusModalBody">
                <v-row style="padding-inline:10px;margin-inline: 10px;">
                    <v-col cols="12" sm="12" md="6">
                        <b-table-simple>
                            <thead>
                                <th class="backBlack text-center">{{ lang.customer_name }}</th>
                                <th class="backBlack text-center">{{ lang.mobile }}</th>
                                <th class="backBlack text-center">{{ lang.vat_number }}</th>
                                <th class="backBlack text-center">{{ lang.crt_number }}</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-center">{{ path_info.customer.customer_name }}</td>
                                    <td class="text-center">{{ path_info.customer.mobile }}</td>
                                    <td class="text-center">{{ path_info.customer.vat_number }}</td>
                                    <td class="text-center">{{ path_info.customer.crt_number }}</td>
                                </tr>
                            </tbody>
                        </b-table-simple>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <b-table-simple>
                            <thead>
                                <th class="backBlack text-center">{{ lang.plate_number }}</th>
                                <th class="backBlack text-center">{{ lang.brand }}</th>
                                <th class="backBlack text-center">{{ lang.model }}</th>
                                <th class="backBlack text-center">{{ lang.car_year }}</th>
                                <th class="backBlack text-center">{{ lang.vin }}</th>
                                <th class="backBlack text-center">{{ lang.engine_meter }}</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-center">{{ path_info.plate_number }}</td>
                                    <td class="text-center">{{ path_info.brand }}</td>
                                    <td class="text-center">{{ path_info.model }}</td>
                                    <td class="text-center">{{ path_info.made_year }}</td>
                                    <td class="text-center">{{ path_info.vin }}</td>
                                    <td class="text-center">{{ path_info.engine_meter }}</td>
                                </tr>
                            </tbody>
                        </b-table-simple>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <div style="background:black;color:white;padding:5px;">{{ lang.items_for_customer_approval }}</div>
                        <b-table-simple>
                            <thead>
                                <th class="backBlack text-center" style="width:50px;" v-if="path_info.items_to_approve.length != 0">
                                    <b-form-checkbox :ref="select_all" @change="selectAll()" /></th>
                                <th class="backBlack text-center">{{ lang.type }}</th>
                                <th class="backBlack text-center">{{ lang.added_date }}</th>
                                <th class="backBlack text-center">{{ lang.item_code }}</th>
                                <th class="backBlack text-center">{{ lang.item_name }}</th>
                                <th class="backBlack text-center">{{ lang.price }}</th>
                                <th class="backBlack text-center">{{ lang.qtty }}</th>
                                <th class="backBlack text-center">{{ lang.total }}</th>
                                <th class="backBlack text-center">{{ lang.vat }}</th>
                                <th class="backBlack text-center">{{ lang.ftotal }}</th>
                                <!-- <th class="backBlack text-center">{{ lang.status }}</th> -->
                                <th class="backRed text-center" style="width:80px;">{{ lang.delete }}</th>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in path_info.items_to_approve" :key="index">
                                    <td class="text-center"><b-form-checkbox :ref="`item_${item.id}`" @change="selectThis(item.id)" /></td>
                                    <td class="text-center" v-if="item.item_type == 2">{{ lang.service_item }}</td>
                                    <td class="text-center" v-if="item.item_type == 1">{{ lang.inventory_item }}</td>
                                    <td class="text-center">{{ item.added_date }}</td>
                                    <td class="text-center">{{ item.item_code }}</td>
                                    <td class="text-center" v-if="item[`item_name${lang.langname}`] != ''">{{ item[`item_name${lang.langname}`] }}</td>
                                    <td class="text-center" v-else>{{ item[`item_name_desc`] }}</td>
                                    <td class="text-center">{{ item.item_price }}</td>
                                    <td class="text-center">{{ item.qty }}</td>
                                    <td class="text-center">{{ item.total }}</td>
                                    <td class="text-center">{{ item.vat }}</td>
                                    <td class="text-center">{{ item.ftotal }}</td>
                                    <!-- <td class="text-center">{{ item.status }}</td> -->
                                    <td class="backRed text-center">
                                        {{ lang.delete }}
                                    </td>
                                </tr>
                            </tbody>
                        </b-table-simple>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <div style="background:black;color:white;padding:5px;">{{ lang.service_work_item }}</div>
                        <b-table-simple>
                            <thead>
                                <!-- <th class="backBlack text-center" style="width:50px;"></th> -->
                                <th class="backBlack text-center">{{ lang.added_date }}</th>
                                <th class="backBlack text-center">{{ lang.item_code }}</th>
                                <th class="backBlack text-center">{{ lang.item_name }}</th>
                                <th class="backBlack text-center">{{ lang.price }}</th>
                                <th class="backBlack text-center">{{ lang.qtty }}</th>
                                <th class="backBlack text-center">{{ lang.total }}</th>
                                <th class="backBlack text-center">{{ lang.vat }}</th>
                                <th class="backBlack text-center">{{ lang.ftotal }}</th>
                                <!-- <th class="backBlack text-center">{{ lang.status }}</th> -->
                                <th class="backRed text-center" style="width:80px;">{{ lang.delete }}</th>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in path_info.items[2][1]" :key="index">
                                    <!-- <td class="text-center"><b-form-checkbox @change="selectThis(item.id)" /></td> -->
                                    <td class="text-center">{{ item.added_date }}</td>
                                    <td class="text-center">{{ item.item_code }}</td>
                                    <td class="text-center" v-if="item[`item_name${lang.langname}`] != ''">{{ item[`item_name${lang.langname}`] }}</td>
                                    <td class="text-center" v-else>{{ item[`item_name_desc`] }}</td>
                                    <td class="text-center">{{ item.item_price }}</td>
                                    <td class="text-center">{{ item.qty }}</td>
                                    <td class="text-center">{{ item.total }}</td>
                                    <td class="text-center">{{ item.vat }}</td>
                                    <td class="text-center">{{ item.ftotal }}</td>
                                    <!-- <td class="text-center">{{ item.status }}</td> -->
                                    <td class="backRed text-center">{{ lang.delete }}</td>
                                </tr>
                            </tbody>
                        </b-table-simple>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <div style="background:black;color:white;padding:5px;">{{ lang.spare_part_included_in_invoice }}</div>
                        <b-table-simple>
                            <thead>
                                <!-- <th class="backBlack text-center" style="width:50px;"></th> -->
                                <th class="backBlack text-center">{{ lang.part_type }}</th>
                                <th class="backBlack text-center">{{ lang.added_date }}</th>
                                <th class="backBlack text-center">{{ lang.item_code }}</th>
                                <th class="backBlack text-center">{{ lang.item_name }}</th>
                                <th class="backBlack text-center">{{ lang.price }}</th>
                                <th class="backBlack text-center">{{ lang.qtty }}</th>
                                <th class="backBlack text-center">{{ lang.total }}</th>
                                <th class="backBlack text-center">{{ lang.vat }}</th>
                                <th class="backBlack text-center">{{ lang.ftotal }}</th>
                                <!-- <th class="backBlack text-center">{{ lang.status }}</th> -->
                                <th class="backRed text-center" style="width:80px;">{{ lang.delete }}</th>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in path_info.items[1][1]" :key="index">
                                    <!-- <td class="text-center"><b-form-checkbox @change="selectThis(item.id)" /></td> -->
                                    <td class="text-center" v-if="item.part_type == 1">{{ lang.from_inventory }}</td>
                                    <td class="text-center" v-else>{{ lang.from_out_of_store }}</td>
                                    <td class="text-center">{{ item.added_date }}</td>
                                    <td class="text-center">{{ item.item_code }}</td>
                                    <td class="text-center" v-if="item[`item_name${lang.langname}`] != ''">{{ item[`item_name${lang.langname}`] }}</td>
                                    <td class="text-center" v-else>{{ item[`item_name_desc`] }}</td>
                                    <td class="text-center">{{ item.item_price }}</td>
                                    <td class="text-center">{{ item.qty }}</td>
                                    <td class="text-center">{{ item.total }}</td>
                                    <td class="text-center">{{ item.vat }}</td>
                                    <td class="text-center">{{ item.ftotal }}</td>
                                    <!-- <td class="text-center">{{ item.status }}</td> -->
                                    <td class="backRed text-center">{{ lang.delete }}</td>
                                </tr>
                            </tbody>
                        </b-table-simple>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <div style="background:black;color:white;padding:5px;">{{ lang.spare_part_not_included_in_invoice }}</div>
                        <b-table-simple>
                            <thead>
                                <!-- <th class="backBlack text-center" style="width:50px;"></th> -->
                                <th class="backBlack text-center">{{ lang.part_type }}</th>
                                <th class="backBlack text-center">{{ lang.added_date }}</th>
                                <th class="backBlack text-center">{{ lang.item_code }}</th>
                                <th class="backBlack text-center">{{ lang.item_name }}</th>
                                <th class="backBlack text-center">{{ lang.price }}</th>
                                <th class="backBlack text-center">{{ lang.qtty }}</th>
                                <th class="backBlack text-center">{{ lang.total }}</th>
                                <th class="backBlack text-center">{{ lang.vat }}</th>
                                <th class="backBlack text-center">{{ lang.ftotal }}</th>
                                <!-- <th class="backBlack text-center">{{ lang.status }}</th> -->
                                <th class="backRed text-center" style="width:80px;">{{ lang.delete }}</th>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in path_info.items[1][2]" :key="index">
                                    <!-- <td class="text-center"><b-form-checkbox @change="selectThis(item.id)" /></td> -->
                                    <td class="text-center">{{ lang.from_inventory }}</td>
                                    <td class="text-center">{{ item.added_date }}</td>
                                    <td class="text-center">{{ item.item_code }}</td>
                                    <td class="text-center" v-if="item[`item_name${lang.langname}`] != ''">{{ item[`item_name${lang.langname}`] }}</td>
                                    <td class="text-center" v-else>{{ item[`item_name_desc`] }}</td>
                                    <td class="text-center">{{ item.item_price }}</td>
                                    <td class="text-center">{{ item.qty }}</td>
                                    <td class="text-center">{{ item.total }}</td>
                                    <td class="text-center">{{ item.vat }}</td>
                                    <td class="text-center">{{ item.ftotal }}</td>
                                    <!-- <td class="text-center">{{ item.status }}</td> -->
                                    <td class="backRed text-center">{{ lang.delete }}</td>
                                </tr>
                                <tr v-for="(item,index) in path_info.items[1][4]" :key="index">
                                    <!-- <td class="text-center"><b-form-checkbox @change="selectThis(item.id)" /></td> -->
                                    <td class="text-center">{{ lang.from_out_of_store }}</td>
                                    <td class="text-center">{{ item.added_date }}</td>
                                    <td class="text-center">{{ item.item_code }}</td>
                                    <td class="text-center" v-if="item[`item_name${lang.langname}`] != ''">{{ item[`item_name${lang.langname}`] }}</td>
                                    <td class="text-center" v-else>{{ item[`item_name_desc`] }}</td>
                                    <td class="text-center">{{ item.item_price }}</td>
                                    <td class="text-center">{{ item.qty }}</td>
                                    <td class="text-center">{{ item.total }}</td>
                                    <td class="text-center">{{ item.vat }}</td>
                                    <td class="text-center">{{ item.ftotal }}</td>
                                    <!-- <td class="text-center">{{ item.status }}</td> -->
                                    <td class="backRed text-center">{{ lang.delete }}</td>
                                </tr>
                            </tbody>
                        </b-table-simple>
                    </v-col>

                </v-row>
            </div>
        </div>
        <addSparePart ref="addSparePart" />
        <addService ref="addService" />
    </div>
</template>

<script>
import axios from 'axios'
import addSparePart from './add_sparepart.vue'
import addService from './add_service.vue'
export default{
    components: {addSparePart,addService},
    data() {
        return {
            showtable: false,
            title: 'show path',
            id: 0,
            path_info: {},
            search: {
                sdate: '',
                edate: '',
            },
            source_id: 0,
            account_name: ''
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        }, 
    },
    methods: {
        addToStore(){
            this.$refs.addSparePart.id = this.id;
            this.$refs.addSparePart.newrow = {
                itemid: 0,
                cardid: this.path_info.card_id,
                part_type: 1,
                invoied_type: 1,
                approved: 1,
                vat_type: 2,
                id: 0,
                item_id: '',
                item_code: '',
                item_name: '',
                qty: '',
                item_price: '',
                total: '',
                vat: '',
                ftotal: '',
            };
        },
        addToService(){
            this.$refs.addService.id = this.id;
            this.$refs.addService.newrow = {
                itemid: 0,
                cardid: this.path_info.card_id,
                part_type: 1,
                invoied_type: 1,
                approved: 1,
                vat_type: 2,
                id: 0,
                item_id: '',
                item_code: '',
                item_name: '',
                qty: '',
                item_price: '',
                total: '',
                vat: '',
                ftotal: '',
            };
        },
        printMe(){
            if(this.path_info.path_type == 1){
                window.open('/api/print.php?newcard'+this.path_info.card_id, '_blank');
            }
            else{
                window.open('/api/print.php?checkupid'+this.path_info.checkup_id, '_blank');
            }
        },
        getPathInfo(){
            this.showtable = true;
            this.title = 'معلومات المسار';
            const post = new FormData();
            post.append('type','getCarPathInfo');
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('auth',cook);
            post.append('data[id]',this.id);
            axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            ).then((response) => {
                if(response && response.data && response.data.results){
                    this.path_info = response.data.results.data[0];
                }
            })
        },
        closeTable(){
            this.showtable = false;
            this.tbrows = []
            this.search = {
                sdate: '',
                edate: '',
            }
            this.classid = 0
            this.account_name = ''
        },
        
    },
    created() {
        //
    },
}
</script>
<style scoped>
.cusModal{
    position: fixed;
    background:#0000001a;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 20000000;
    display:flex;
    justify-content: center;
    justify-items: center;
    align-items: center;;
}
.cusModalCont{
    position:relative;
    width:70%;
    height: 70vh;
    border:1px solid #bbb;
    background:#fff;
    box-shadow: 0px 1px 1px 1px #0000001a;
    border-radius: 5px;
}
.cusModalClose{
    position: absolute;
    top:2px;
    left:10px;
    background:red;
    color:#fff;
    border:1px solid #555;
    border:#ccc;
    border-radius: 5px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
}
.cusModalCloseL:hover{
    background: darkred;
}
.cusModalTitle{
    padding:5px;
    text-align:start;
    border-color: #bbb !important;
    border-bottom-color: #000 !important;
}
.cusModalBody{
    width:100%;
    overflow: auto;
    height:calc(70vh - 50px);
    max-height:calc(70vh - 50px);
    padding:5px;
    margin:5px 10px;
}
.eportBtn{
    position: absolute;
    top:2px;
    left:60px;
    border:1px solid #555;
    border:#ccc;
    border-radius: 2px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
    display:flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
}
.eportBtn div{
    padding:2px 5px;
    width:100px;
    font-size:.8rem;
    background:blue;
    margin-inline-start:5px;
    color:#fff;
    border-radius: 3px;
    text-align:center;
}
</style>

