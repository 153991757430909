<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" style="display:none;"  />
        
        <div style="margin-bottom:100px;">
            <tabsComp :tabs="tabs" />
            <v-row>
                <v-col cols="12">
                    <div class="dashPathes">
                        <div class="pathNumber" v-for="(item,index) in carPathes" :key="index" style="cursor:pointer">
                            <div class="pathTitle">{{item.plate_number}} - {{ item.vin }}</div>
                                {{ item.path_number }}
                            <div  @click="openCard(item.path_number)" v-b class="pathFoot">{{ lang.action }}</div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
        <Footer />
        <viewPathes ref="viewPathes" />
    </div>
</template>

<script>
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import tabsComp from '@/components/tabsComp.vue'
import ExportsPrint from '@/components/exports-print.vue';
import viewPathes from '@/components/viewPathes.vue';
import axios from 'axios'
export default{
    components: {HeaderPortrate,Footer,tabsComp,ExportsPrint,viewPathes},
    data(){
        return {
            carPathes: [{path_number: 1},{path_number: 2}],
            showtable: false,
            showFix: false,
            sd: {
                day: 1,
                month: 1,
                year: (new Date()).getFullYear()
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear()
            },
            
            search: '',
        }
    },
    computed:{
        lang: function(){
          if(this.$cookies.get(this.$store.state.LangCooki) != null){
              if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
              return this.$store.state.lang.en;
              }else{
              return this.$store.state.lang.ar;
              }
          }
          else if(this.$store.state.deflang == 'en'){
              return this.$store.state.lang.en;
          }else{
              return this.$store.state.lang.ar;
          }
      },


      items:function() {
          return {
            text: this.lang.invoices,
            disabled: true,
            href: '/invoicelist/',
          }
      },
      tabs: function(){
        
        const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
           
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                // // console.log("value",license);
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                      if(alicense[value.perms[0]] || value.perms[0] == 'any')
                          t.push(value)
                    }
                }
            })
        
            return t;
      }
    },
    methods:{
        openCard(id){
            //window.open('./api/print.php?path_number='+id,"_blank")
            this.$refs.viewPathes.id = id;
            this.$refs.viewPathes.path_info = {};
            this.$refs.viewPathes.getPathInfo();
        },

        getDashboard(){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            let post = new FormData();
            post.append('type','getCarPathes');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('auth',cook);
            post.append('data[start]',this.sdate);
            post.append('data[end]',this.edate)
            axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            ).then((response) => {
                if(response && response.data && response.data.results){
                    this.carPathes = response.data.results.data;
                }
            })
            // console.log(response.data)
            
        }
    },
    created(){
        this.getDashboard();
    }
}

</script>

<style scoped>
.dashPathes{
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:center;
    justify-items:center;
    align-self:center;
    
}
.pathNumber{
    background: #fffb00;
    color:#000;
    display: flex;
    flex-direction:column;
    flex-wrap:wrap;
    justify-content:space-between;
    justify-items:center;
    align-items:center;
    font-size:5rem;
    width:200px;
    height: 200px;
    margin:5px;
}
.pathTitle{
    background-color:#000;
    color:#fff;
    width:100%;
    text-align:center;
    padding:3px;
}
.pathFoot{
    background-color:blue;
    color:#fff;
    width:100%;
    text-align:center;
    padding:3px;
    cursor: pointer;
}
.cusModal{
    position: fixed;
    background:#0000001a;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 20000000;
    display:flex;
    justify-content: center;
    justify-items: center;
    align-items: center;;
}
.cusModalCont{
    position:relative;
    width:70%;
    height: 70vh;
    border:1px solid #bbb;
    background:#fff;
    box-shadow: 0px 1px 1px 1px #0000001a;
    border-radius: 5px;
}
.cusModalClose{
    position: absolute;
    top:2px;
    left:10px;
    background:red;
    color:#fff;
    border:1px solid #555;
    border:#ccc;
    border-radius: 2px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
}
.cusModalCloseL:hover{
    background: darkred;
}
.cusModalTitle{
    padding:5px;
    text-align:center;
    border-color: #bbb !important;
    border-bottom-color: #000 !important;
}
.cusModalBody{
    overflow: auto;
    height:calc(70vh - 50px);
    max-height:calc(70vh - 50px);
    padding:5px;
}
.dashBoard{
    margin-inline:10px;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:space-around;
    justify-items:center;
    align-self:center;
}
.cusModal{
    position: fixed;
    background:#0000001a;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 20000000;
    display:flex;
    justify-content: center;
    justify-items: center;
    align-items: center;;
}
.cusModalCont{
    position:relative;
    width:70%;
    height: 70vh;
    border:1px solid #bbb;
    background:#fff;
    box-shadow: 0px 1px 1px 1px #0000001a;
    border-radius: 5px;
}
.cusModalClose{
    position: absolute;
    top:2px;
    left:10px;
    background:red;
    color:#fff;
    border:1px solid #555;
    border:#ccc;
    border-radius: 2px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
}
.cusModalCloseL:hover{
    background: darkred;
}
.cusModalTitle{
    padding:5px;
    text-align:center;
    border-color: #bbb !important;
    border-bottom-color: #000 !important;
}
.cusModalBody{
    overflow: auto;
    height:calc(70vh - 50px);
    max-height:calc(70vh - 50px);
    padding:5px;
}
</style>