<template>
    <div>
        <b-sidebar id="add_service" width="50rem" backdrop aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right title="سند قبض" shadow >
        <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>{{lang.add_service}}</span>
                </div>
                <div @click="hide" id="payHides" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>{{lang.close}}</span>
                </div>
            </div>
            <v-divider></v-divider>

            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
   
                    <v-col cols="12" md="3" sm="12">
                        <label for="input-live">{{lang.add_to_invoice}}</label>
                        <div class="flexTay">
                            <b-input-group>
                            <b-form-select class="selborder text-center" style="" v-model="newrow.invoied_type" :options="isInvoiced"/>
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                    <i class="fas fa-arrow-down"></i>
                                    </b-input-group-append>
                                </b-input-group>
                        </div>
                    </v-col>
                    <v-col cols="12" md="3" sm="12">
                        <label for="input-live">{{lang.vat_type}}</label>
                        <div class="flexTay">
                            <b-input-group>
                            <b-form-select class="selborder text-center" style="" v-model="newrow.vat_type" @change="calcNewRow()" :options="vatTypesList"/>
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                        <i class="fas fa-arrow-down"></i>
                                        </b-input-group-append>
                                    </b-input-group>
                        </div>
                    </v-col>
                    <v-col cols="12" md="3" sm="12">
                        <label for="input-live">{{lang.approved_type}}</label>
                        <div class="flexTay">
                            <b-input-group>
                            <b-form-select class="selborder text-center" style="" v-model="newrow.approved" :options="approvedTypes"/>
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                    <i class="fas fa-arrow-down"></i>
                                    </b-input-group-append>
                                </b-input-group>
                        </div>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                        <v-row>
                            <v-col cols="12" md="5" sm="12">
                                <label for="input-live">{{lang.item_name}}</label>
                                <div class="flexTay">
                                    <b-form-input class="inborder text-center" style="" v-model="newrow.item_name"/>
                                </div>
                            </v-col>
                            <v-col cols="12" md="2" sm="12">
                                <label for="input-live">{{lang.qtty}}</label>
                                <div class="flexTay">
                                    <b-form-input class="inborder text-center" @change="calcNewRow()" style="" v-model="newrow.qty"/>
                                </div>
                            </v-col>
                            <v-col cols="12" md="2" sm="12">
                                <label for="input-live">{{lang.item_price}}</label>
                                <div class="flexTay">
                                    <b-form-input class="inborder text-center" @change="calcNewRow()" style="" v-model="newrow.item_price"/>
                                </div>
                            </v-col>
                            <v-col cols="12" md="2" sm="12">
                                <label for="input-live">{{lang.total}}</label>
                                <div class="flexTay">
                                    <b-form-input class="inborder text-center" readonly style="" v-model="newrow.total"/>
                                </div>
                            </v-col>
                            <v-col cols="12" md="2" sm="12">
                                <label for="input-live">{{lang.vat}}</label>
                                <div class="flexTay">
                                    <b-form-input class="inborder text-center" readonly style="" v-model="newrow.vat"/>
                                </div>
                            </v-col>
                            <v-col cols="12" md="3" sm="12">
                                <label for="input-live">{{lang.ftotal}}</label>
                                <div class="flexTay">
                                    <b-form-input class="inborder text-center" readonly style="" v-model="newrow.ftotal"/>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>  
                </v-row>
            </div>
        </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" id="adVouBTN" @click="addStartInv()" variant="success" class="ma-2" style="width:100px;" v-if="status == 1">{{lang.add}}</b-button>
                </div>
            </template>
        </b-sidebar>
        
    </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';

export default{
    data() {
        return {
            id: 0,
            status: 1,
            item_rows: [],
            newrow: {
                itemid: 0,
                cardid: 0,
                part_type: 1,
                invoied_type: 1,
                approved: 1,
                vat_type: 2,
                id: 0,
                item_id: '',
                item_code: '',
                item_name: '',
                qty: '',
                item_price: '',
                total: '',
                vat: '',
                ftotal: '',
            },
            sd: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            vatTypes: [],
        }
    },
    computed: {
        lang: function()
        {
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }
            else{
                return this.$store.state.lang.ar;
            }
        },
        partTypes: function(){
            return [
                {value: 1, text: this.lang.from_inventory},
                {value: 2, text: this.lang.from_out_of_store}
            ];
        },
        isInvoiced: function(){
            return [
                {value: 1, text: this.lang.invoiced},
                {value: 2, text: this.lang.not_invoiced}
            ];
        },
        approvedTypes: function(){
            return [
                {value: 1, text: this.lang.need_approval},
                {value: 2, text: this.lang.approved}
            ];
        },
        vatTypesList: function(){
            const t = [];
            for(let i=0;i<this.vatTypes.length;i++){
                t.push({value: this.vatTypes[i].value, text: this.vatTypes[i][`name_${this.lang.langname}`]});
            }
           
            return t;
        }
    },
    created() {
        // this.received_total = this.cAmount;
        // setTimeout(() => {this.getPyamentType()},2000);
        this.getVatTipes();
    },
    methods: {
        calcNewRow(){
            if(isNaN(this.newrow.qty) || isNaN(this.newrow.item_price) || this.newrow.qty == '' || this.newrow.item_price == ''){
                this.newrow.total = 0;
                this.newrow.vat = 0;
                this.newrow.ftotal = 0;
                return false;
            }
            const sums = parseFloat(this.newrow.qty) * parseFloat(this.newrow.item_price);
            const vs = this.$calcVat(sums,this.newrow.vat_type);
            this.newrow.total = vs.tot;
            this.newrow.vat = vs.vat;
            this.newrow.ftotal = vs.ftot;
        },
        getProducts() {
            if(this.newrow.itemcode == ''){
                return false;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getProducts");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[item_number]',this.newrow.item_code);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                
                if(response.data.results.data.length > 0 || !this.$store.state.licenseType.requirePurchase){
                    
                    if(response.data.results.data[0].qty > 0 || !this.$store.state.licenseType.require_itemcode || !this.$store.state.licenseType.requirePurchase){
                        this.newrow.item_code = response.data.results.data[0].item_code;
                        this.newrow.item_name = response.data.results.data[0][`item_name${ this.lang.langname}`];
                        this.newrow.item_price = response.data.results.data[0].default_price;
                        this.newrow.qty = 1;
                        // this.calcKit();
                        this.calcNewRow();
                    }else{
                        
                        
                        let message = this.lang.error_no_qty;
                        this.$snotify.error(message, this.lang.alert, {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.lang.cancel, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                        // document.getElementById('addModalBTN').click()
                                    } 
                                },
                                {
                                    text: this.parent.purchase, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                        
                                    } 
                                },
                            ]
                        });
                    }
                    
                }else{
                    let message = this.lang.error_no_qty;
                        this.$snotify.error(message, this.lang.alert, {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.lang.cancel, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                        // document.getElementById('addModalBTN').click()
                                    } 
                                },
                                {
                                    text: this.lang.purchase, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                        
                                    } 
                                },
                            ]
                        });
                }
                
            })
        },
        postInv()
        {
            let message = "انت الان تقوم بترحيل التسوية للمخزون .. لن تستطيع التراجع عن هذه الخطوة هل انت متأكد؟";
            this.$snotify.error(message, this.lang.alert, {
                timeout: 1000000,
                showProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                position: SnotifyPosition.centerCenter,
                buttons: [
                    {
                        text: "مراجعة التسوية",
                        class: 'backBlue', 
                        action: (toast) => {
                            this.$snotify.remove(toast.id);
                            return false; 
                        } 
                    },
                    {
                        text: "الغاء الامر", 
                        class: 'backRed', 
                        action: (toast) => {
                            this.$snotify.remove(toast.id);
                            document.getElementById('payHide').click();
                            return false;
                        } 
                    },
                    {
                        text: "ترحيل الرصيد", 
                        class: 'backGreen', 
                        action: (toast) => {
                            this.$snotify.remove(toast.id); 
                            this.doPostInv();
                        } 
                    },
                ]
            });
        },
        getVatTipes(){
            const post = new FormData();
            post.append("type",'getStaticOptions');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[phrase]',"vattypes");
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    this.vatTypes = res.results.data;
                }
            );
        },
        getStores(){
            const post = new FormData();
            post.append("type",'getStores');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',0);
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    this.stores = res.results.data;
                }
            );
        },
        addStartInv()
        {
            if(this.newrow.qty == '' || this.newrow.item_price == ''){
                let message = "لا يمكن اضافة صنف فارغ";
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.cancel, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                                // document.getElementById('addModalBTN').click()
                            } 
                        },
                        {
                            text: this.lang.purchase, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                                
                            } 
                        },
                    ]
                });
                return false;
            }
            document.getElementById('adVouBTN').disabled = true;
            const post = new FormData();
            post.append("type",'addServiceToCard');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[newrow]',JSON.stringify(this.newrow));
            post.append('data[cardid]',JSON.stringify(this.newrow));
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    document.getElementById('adVouBTN').disabled = false;
                    const res = response.data;
                    this.$snotify.success(this.lang.added_success, this.lang.success, {
                        position: SnotifyPosition.rightTop,
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true
                    });
                    document.getElementById('payHides').click();
                    this.$parent.getPathInfo();
                }
            );
        },
        addItem(){
            if(this.newrow.item_code == '' || this.newrow.qty == 0){
                this.$snotify.error('لا يمكن اضافة صنف فارغ', this.lang.error, {
                    position: SnotifyPosition.rightTop,
                    timeout: 2000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true
                });
                return false;
            }
            this.item_rows.push(this.newrow);
            this.newrow = {
                id: 0,
                item_id: '',
                item_code: '',
                item_name: '',
                qty: 0,
            };
        },
        removeItem(index){
            this.item_rows.splice(index,1);
        },
        getProduct(code,index)
        {
            if(code == ''){
                return false;
            }
            const post = new FormData();
            post.append("type",'getProducts');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[item_number]',code);
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res.results.data.length == 0){
                        this.$snotify.error('الصنف غير موجود في المخزون يرجى اضافة الصنف اولا', this.lang.error, {
                            position: SnotifyPosition.rightTop,
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true
                        });
                        return false;
                    }
                    if(index == 0){
                        this.newrow.item_name = res.results.data[0][`item_name${this.lang.langname}`];
                        this.newrow.item_id = res.results.data[0].id;
                        this.newrow.qty = 1;
                    }else{
                        this.item_rows[index][`item_name`] = res.results.data[0][`item_name${this.lang.langname}`];
                        this.item_rows[index][`item_id`] = res.results.data[0].id;
                        
                    }
                }
            );
        },
        getStarterInventory()
        {
            const post = new FormData();
            post.append("type",'getStoreSettlementItmes');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',this.id);
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    this.item_rows = res.results.data[0].rows;
                    this.status = res.results.data[0].status;
                    this.store_id = res.results.data[0].store_id;
                    this.sd = {
                        day: (new Date(res.results.data[0].starter_date)).getDate(),
                        month: (new Date(res.results.data[0].starter_date)).getMonth() + 1,
                        year: (new Date(res.results.data[0].starter_date)).getFullYear(),
                    };
                }
            );
        }
        
    },
}
</script>